<template>
  <div>
    <div class="scratch p-4 text-center">
      <h1 class="text-center py-5">刮刮卡</h1>

      <div class="position-relative m-auto" style="height: 300px; width: 300px">
        <div class="award d-flex justify-content-center align-items-center">
          <div>
            {{
              $route.query.act && $route.query.act == 1 ? "恭喜中獎" : "未中獎"
            }}
          </div>
        </div>
        <canvas
          ref="cover"
          class="cover"
          id="cover"
          width="300"
          height="300"
          @mousedown="eventDown"
          @mouseup="eventUp"
          @mousemove="eventMove"
          @touchstart="eventDown"
          @touchend="eventUp"
          @touchmove="eventMove"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      mousedown: false,
      ctxCover: null,
      w: 0,
      h: 0,
    };
  },
  mounted() {
    this.ctxCover = this.$refs.cover.getContext("2d");
    this.w = this.ctxCover.canvas.width;
    this.h = this.ctxCover.canvas.height;
    this.drawCover();
  },
  methods: {
    eventUp: function (ev) {
      console.log("up");
      ev = ev || event;
      ev.preventDefault();
      this.mousedown = false;
    },
    eventDown: function (ev) {
      console.log("down");
      ev = ev || event;
      ev.preventDefault();
      this.mousedown = true;
    },
    eventMove: function (ev) {
      ev = ev || event;
      ev.preventDefault();
      if (this.mousedown) {
        if (ev.changedTouches) {
          ev = ev.changedTouches[ev.changedTouches.length - 1];
        }
        var x = ev.clientX - this.$refs.cover.getBoundingClientRect().x;
        var y = ev.clientY - this.$refs.cover.getBoundingClientRect().y;
        this.ctxCover.beginPath();
        this.ctxCover.arc(x, y, 15, 0, Math.PI * 2);
        this.ctxCover.fill();
        this.alertInfo();

        console.log("move", ev.clientX, ev.clientY, x, y);
      }
    },
    drawCover: function () {
      this.ctxCover.canvas.style.opacity = 1;
      this.ctxCover.fillStyle = "silver";
      this.ctxCover.fillRect(0, 0, this.w, this.h);

      console.log("drawCover", this.w, this.h);

      //判斷當前是否為第一次刮開，不是則清除上一次區域
      if (this.ctxCover.globalCompositeOperation != "destination-out") {
        this.ctxCover.globalCompositeOperation = "destination-out";
      } else {
        console.log("clear");
        this.ctxCover.clearRect(0, 0, this.w, this.h);
      }
    },

    alertInfo: function () {
      var data = this.ctxCover.getImageData(0, 0, this.w, this.h).data;
      var n = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i] == 0) {
          n++;
        }
      }
      if (n >= data.length * 0.75) {
        this.ctxCover.globalCompositeOperation = "destination-over";
        this.ctxCover.canvas.style.opacity = 0;       
        alert(this.$route.query.act && this.$route.query.act == 1 ? "恭喜中獎" : "未中獎");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scratch {
  background: red;

  canvas {
    // aspect-ratio: 1/1;

    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .award {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .cover {
    width: 100%;
    height: 100%;
  }
}
</style>
